.logo {
    display: inline-block;
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-bottom: rhythm(8);

    @media(max-width: 480px) {
        width: 60%;
        margin-bottom: rhythm(4);
    }

    @media(max-width: 320px) {
        width: 80%;
    }
}

#hero {
    padding: 0;
}
