.slideshow,
.slides,
.slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.slide {
    background-position: center center;
    background-size: cover;
}

.controls {
    position: absolute;
    z-index: 9999;
    bottom: 1.5rem;
    width: 100%;
    text-align: center;
}

.slideshow-pager .pager {
    display: inline-block;
    border-radius: 50%;
    border: 2px solid $blue;
    width: 1rem;
    height: 1rem;
    margin: 0 rhythm(2);
    transition: all ease .3s;
    cursor: pointer;
}

.slideshow-pager .pager:hover,
.slideshow-pager .cycle-pager-active {
    background: $blue;
}
