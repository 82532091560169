// this class is used to hook in the
// parallax effect
.rellax {
    z-index: 999;
}

.block-1,
.block-2,
.block-3 {
    // animation-name: rotate;
    // animation-duration: 30s;
    // animation-iteration-count: infinite;
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}



.block-1 {
    position: absolute;
    top: 85vh;
    left: 0;
    right: 0;
    max-width: 200px;
    height: auto;
    margin: 0 auto;

    @media(max-width: 800px) {
        width: 150px;
    }

    @media(max-width: 480px) {
        width: 100px;
        bottom: 1rem;
    }
}

.block-2 {
    z-index: 999;
    max-width: 350px;
    height: auto;
    position: absolute;
    left: -5rem;
    top: 15rem;

    @media(max-width: 600px) {
        width: 250px;
        top: 6rem;
    }

    @media(max-width: 599px) {
        display: none;
    }
}

.block-3 {
    z-index: 999;
    position: absolute;
    right: 5rem;
    bottom: 3rem;
    max-width: 220px;
    height: auto;

    @media(max-width: 800px) {
        width: 150px;
        right: 1.5rem;
        bottom: 8rem;
    }

    @media(max-width: 600px) {
        bottom: 15rem;
    }

    @media(max-width: 480px) {
        bottom: 18rem;
        width: 100px;
    }

    @media(max-width: 320px) {
        bottom: 22rem;
    }
}


.slideshow {
    // margin-top: rhythm(-8);
}

.slideshow .slide,
.section--img {
    box-shadow: inset 0 0 30px 5px rgba(0, 0, 0, 0.15);
}
