// vars
$type-body-font: Helvetica, sans-serif !default;
$type-body-size: 16px !default;

// rhythm
// best to have increments for more control adjusting
$type-leading: 1.5 !default;
$type-rhythm: $type-leading / 4 !default;

// scale
$type-scale: 1.15 !default;

@function rhythm($num) {
    @return $type-rhythm * $num * 1rem;
}

// an positive or negative number, but not zero
@mixin scale($num) {

    $size: 1;

    @if $num > 0 {
        @for $i from 1 to $num {
            $size: $size * $type-scale;
        }
    } @else {

        $size: $size / $type-scale;

        @for $i from 1 to $num * -1 {
            $size: $size / $type-scale;
        }
    }

    font-size: $size * 1rem;

    @if ( $size >= $type-leading ) {
        line-height: ( round($size) * $type-leading) * 1rem;
    } @else {
        line-height: $type-leading * 1rem;
    }
}


html,
body {
    font-family: $type-body-font;
    font-size: $type-body-size;
    line-height: $type-leading;
    -webkit-font-smoothing: antialiased;
}

// vertical rhythm
h1, h2, h3, h4, h5, h6,
p,
ol,
ul,
dl,
figure,
address,
table,
pre code,
pre,
img {
    margin-bottom: $type-leading * 1rem;
}


// heading styles would be for all headings
// so maybe bolder than original text
.heading,
h1, h2, h3, h4, h5, h6 {
    // @extend .bold;
}

// title and subtitle might be slightly different to headings
// maybe not bold, or sub-title is knocked back
.title {}
.subtitle {}
.standfirst { @include scale(2); }

// this is strictly font sizing
// alpha would be the same size as h1
// but may not have sames styles i.e bold
h1, .alpha   { @include scale(6); }
h2, .beta    { @include scale(5); }
h3, .gamma   { @include scale(4); }
h4, .delta   { @include scale(3); }
h5, .epsilon { @include scale(2); }
h6, .zeta    { @include scale(1); }


// utility classes
// can be included in css so all @extend .bold is the same
// clasify what bold means in a project
// could mean a particular font stack (i.e font-family: 'Futura Bold', not font: Future; font-weight: bold)
.bold { font-weight: bold; }

// usage
b,
strong {
    @extend .bold;
}

// italic
.italic { font-style: italic; }
// usage
var,
cite,
em,
i {
    @extend .italic;
}

img {
    max-width: 100%;
}

// mark
mark {
    background-color: yellow;
}

// strike
s,
del {
    text-decoration: line-through;
}

// underline
ins,
u {
    text-decoration: underline;
}

// code
code,
samp,
kbd {
    display: inline-block;
    font-family: monospace;
    letter-spacing: 0;
}

pre code {
    background-color: #eee;
    display: block;
    padding: $type-leading/2 * 1rem;
    overflow-x: scroll;
}


.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }

.left { text-align: left; }
.right { text-align: right; }
.center { text-align: center; }

// check your type
.baseline {
    background-image: linear-gradient(to bottom,#0ff 0,rgba(255,255,255,0)1px), linear-gradient(to bottom,rgba(red, 0.1) 0,rgba(255,255,255,0)1px);
    background-repeat: repeat-y;
    background-size: 100% $type-leading * 1rem, 100% ($type-leading / 3) * 1rem;
}


// lists
ol { list-style-type: decimal; }
ul { list-style-type: disc; }


// Definition list
dl {}
dt { @extend .bold; }
dd { @extend .italic; }

// quotes
blockquote {
    @include scale(2);
}
