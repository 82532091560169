.create-table {
    margin-top: rhythm(16);
    width: 100%;
    table-layout: fixed;
}

.create-table td {
    padding: rhythm(2);

    @media(max-width: 800px) {
        display: block;
        width: 100%;
    }
}


.create-content-first { animation-delay: 0s; }
.create-content-second { animation-delay: .5s; }
.create-content-third { animation-delay: 1s; }


