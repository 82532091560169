.field {
    display: block;
    margin-bottom: rhythm(4);
}

label {
    display: block;
    margin-bottom: rhythm(1);
}

input[type="text"],
input[type="email"],
input[type="submit"],
textarea {
    -webkit-appearance: none;
}

input[type="text"],
input[type="email"],
textarea {
    // reset
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    font: inherit;


    font: inherit;
    padding: rhythm(2);
    background: $white;
    border: 2px solid $elements;
}

@mixin button($background, $text) {

    // button reset styles
    display: inline-block;
    font: inherit;
    margin: 0;
    padding: 0;
    box-sizing: border-box;


    // button styling below
    background: transparent;
    padding: rhythm(2) rhythm(4);
    border: 2px solid $background;
    color: $background;
    transition: all ease .3s;

    &:hover {
        color: $text;
        background: $background;
        cursor: pointer;
    }
}

.btn {
    @include button($elements, $white);
}

.error {
    color: $red;
    margin-top: rhythm(2);
    text-align: left;
}
