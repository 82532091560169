// Type
.center { text-align: center; }
.right { text-align: right; }
.left { text-align: left; }

//Spacing
.no-margin {
  margin: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding-bottom, .section.no-padding-bottom,  .column.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-top, .section.no-padding-top,  .column.no-padding-top {
  padding-top: 0;
}

//position

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.green {
    color: green;
}
