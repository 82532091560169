.section {
    box-sizing: border-box;
    position: relative;
    display: table;
    width: 100%;
    height: 100%;

    padding: rhythm(8) 0 rhythm(4);

    min-height: 100vh;
}

.section-overlay {
    // display: table;
    position: absolute;
    width: 100%;
    // height: 100vh;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.section-midway {
    display: table;
    width: 100%;
    height: 100%;
}

.section-content {
    display: table-cell;
    vertical-align: middle;
}

.section--black {
    background: #222;
}

.section--grey-light {
    background: $grey-light;
    color: $elements;
}

.section--grey-dark {
    background: $grey-dark;
    color: $red;
}

.section--purple {
    background: $purple;
    color: $red;
}

.section--red {
    background: $red;
    color: $white;
}

.section--pink {
    background: $pink;
    color: $elements;
}

.section--blue {
    background: $blue;
    color: $elements;
}

.section--img {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}
