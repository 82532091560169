// Base Styles
* {
  box-sizing: border-box;
}

html,
body {
  background-color: $grey-light;
  color: $black;
}

// Links

a,
a:link,
a:visited,
a:active,
a:hover {
  color: inherit;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

