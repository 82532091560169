// Colours
$black: #000;
$white: #fff;

$purple: #5B4796;
$red: #EA4D4B;

$grey-dark: #4A4A4A;
$grey-light: #F0F0F0;

// new colours
$blue: #6DCAF3;
$pink: #EFCAE1;

$elements: #312F48;

// Type settings
// set the body font
$type-body-font: 'ProbaPro-Light', sans-serif;
// set the base body size
$type-body-size: 21px;

// rhythm
// set the line-height
$type-leading: 1.5;

// set an increment for more control adjusting line-heights, paddings and margins
// used by rhythm(4) function
$type-rhythm: $type-leading / 4;

// set the type scale for working with headings etc
// used with @include scale(1);
$type-scale: 1.25;
